import "./css/App.css";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Events from "./pages/Events";
import Registry from "./pages/Registry";
import FAQ from "./pages/FAQ";
import Gallery from "./pages/Gallery";
import RSVP from "./pages/RSVP";
import CeremonyRSVP from "./pages/CeremonyRSVP";
import ReceptionRSVP from "./pages/ReceptionRSVP";
import NotFound from "./pages/NotFound";

function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/gallery" element={<Gallery/>}></Route>
            <Route path="/events" element={<Events/>}></Route>
            <Route path="/registry" element={<Registry/>}></Route>
            <Route path="/faq" element={<FAQ/>}></Route>
            <Route path="/rsvp" element={<RSVP/>}></Route>
            <Route path="/ceremonyRSVP" element={<CeremonyRSVP/>}></Route>
            <Route path="/receptionRSVP" element={<ReceptionRSVP/>}></Route>
            <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;