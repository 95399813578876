import Toolbar from "../pageDefaults/toolbar";
import React from "react";

export default function ReceptionRSVP(props) {
    return (
        <>
            <Toolbar/>

            <div className="centered">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdNusNmykZsTLm2ebb6KX5SehQIWBMeyfLyxGtJSFAUMIBlNQ/viewform?embedded=true" width="100%" height="791" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
            </div>

            <div className="centered">
                <hr/>
                <p className="sub">
                    website designed and developed by carsyn
                </p>
            </div>
        </>
    );
}