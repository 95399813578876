import Toolbar from "../pageDefaults/toolbar";
import "../css/toolbar.css";
import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

export default function Registry(props) {

    return (
        <>
            <Toolbar/>
            <div className="centered padded">
                <h6>Click to be Redirected to Registries</h6>

                <a href="https://www.amazon.com/wedding/registry/15XT6IWM6SDCS" target="_blank">
                    <img src="/amazon-logo.png" alt="Amazon Logo"/>
                </a>

                <a href="https://www.potterybarn.com/registry/bplmk6pzlp/registry-list.html" target="_blank">
                    <img src="/PotteryBarn-logo.png" alt="Pottery Barn Logo"/>
                </a>
            </div>

            <div className="centered">
                <hr/>
                <p className="sub">
                    website designed and developed by carsyn
                </p>
            </div>
        </>
    );
}