import Toolbar from "../pageDefaults/toolbar";
import React from "react";

export default function FAQ(props) {
    return (
        <>
            <Toolbar/>
            <div className="centered">
                <h4>Distinction Between Ceremony and Reception</h4>
                <p>
                    Please note the distinction between the ceremony and the reception. The ceremony is limited to those who have received a specific invitation for the ceremony. The reception is open to to extended family and friends.
                </p>
            </div>

            <div>
                <div className="centered">
                    <h5>
                        Ceremony
                    </h5>
                    <h4>RSVPs</h4>
                    <p>
                        If you are planning on attending the ceremony, please RSVP by Februrary 1, 2023 by clicking on the RSVP tab and clicking "Ceremony RSVP."
                    </p>
                </div>

                <div className="centered">
                    <h4>Ceremony Dress Code</h4>
                    <p>
                        Please wear beach casual wedding attire. Pictures will be taken on the beach following the ceremony.
                    </p>
                </div>

                <div className="centered">
                    <h4>Photos at Ceremony</h4>
                    <p>
                        We ask that you refrain from taking photos during the ceremony. We will have a photographer taking photos during and after the ceremony.
                    </p>
                </div>

                <div className="centered">
                    <h4>Parking</h4>
                    <p>
                        For those who are not staying at the Wailea Beach Resort, there will be an additional fee to park for the ceremony. Self parking is $35 per vehicle, and valet parking is $50 per vehicle.
                    </p>
                </div>

                <div className="centered">
                    <h5>
                        Reception
                    </h5>
                    <h4>Date Change</h4>
                    <p>
                        The reception, originally set for July 13, 2024, has been pushed back 1 year, making the reception date now July 12, 2025. Due to financial reasons, this is the best solution to be able to have a fun reception with the activities and guests we want to celebrate with.
                    </p>

                </div>

                <div className="centered">
                    <h4>Reception Dress Code</h4>
                    <p>
                        The reception will be held on the lake. Please wear casual wedding attire.
                    </p>
                </div>

                <div className="centered">
                    <h4>RSVPs</h4>
                    <p>
                        If you are planning on attending the reception, please RSVP by February 1, 2025 by clicking on the RSVP tab and clicking "Reception RSVP."
                    </p>
                </div>

                <div className="centered">
                    <h4>Food to be Served</h4>
                    <p>
                        TBD
                    </p>
                </div>

                <div className="centered">
                    <h4>Photos at Reception</h4>
                    <p>
                        There will be a photographer at the reception, but feel free to take your own photos as well!
                    </p>
                </div>

                <div className="centered">
                    <h4>Parking</h4>
                    <p>
                        Feel free to park along the side of the road near the residence. Additionally, there is capacity for about 20 cars to park at the bottom of the hill by the lake. There will be directional assistance for parking to make this process easier.
                    </p>
                </div>

                <div className="centered">
                    <h4>Getting to the Reception</h4>
                    <p>
                        Depending on where you parked, you may need to walk to get to the reception. To get to the reception, there is a large hill with stairs leading down to the lake. For those who are not able to park down by the lake and who do not want to walk down the stairs, there will be shuttling accommodations to bring guests from the parking area down to the reception area. Additionally, there will be signage around the property to direct guests where to go.
                    </p>
                </div>

                <div className="paddedBottom"></div>
            </div>

            <div className="centered">
                <hr/>
                <p className="sub">
                    website designed and developed by carsyn
                </p>
            </div>
        </>
    );
}