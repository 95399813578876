import Toolbar from "../pageDefaults/toolbar";
import "../css/gallery.css";
import React from "react";
import ModalImage from "react-modal-image";

export default function Gallery(props) {

    return (
        <>
            <Toolbar/>
            <div className="gallery">

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8689.jpg"
                    large="/gallery/IMG_8689.jpg"
                    hideDownload
                    hideZoom
                />

                <div className="horizontal">
                <ModalImage
                    small="/gallery/IMG_8690.jpg"
                    large="/gallery/IMG_8690.jpg"
                    hideDownload
                    hideZoom
                />
                </div>

                <div className="horizontal">
                    <ModalImage
                        small="/gallery/IMG_8696.jpg"
                        large="/gallery/IMG_8696.jpg"
                        hideDownload
                        hideZoom
                    />
                </div>

                <div className="horizontal">
                    <ModalImage
                        small="/gallery/IMG_8699.jpg"
                        large="/gallery/IMG_8699.jpg"
                        hideDownload
                        hideZoom
                    />
                </div>

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8776.jpg"
                    large="/gallery/IMG_8776.jpg"
                    hideDownload
                    hideZoom
                />

                <div className="horizontal">
                    <ModalImage
                        small="/gallery/IMG_8731.jpg"
                        large="/gallery/IMG_8731.jpg"
                        hideDownload
                        hideZoom
                    />
                </div>

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8702.jpg"
                    large="/gallery/IMG_8702.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8706.jpg"
                    large="/gallery/IMG_8706.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8713.jpg"
                    large="/gallery/IMG_8713.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8716.jpg"
                    large="/gallery/IMG_8716.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8722.jpg"
                    large="/gallery/IMG_8722.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8701.jpg"
                    large="/gallery/IMG_8701.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8724.jpg"
                    large="/gallery/IMG_8724.jpg"
                    hideDownload
                    hideZoom
                />

                <div className="horizontal">
                    <ModalImage
                        small="/gallery/IMG_8728.jpg"
                        large="/gallery/IMG_8728.jpg"
                        hideDownload
                        hideZoom
                    />
                </div>

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8730.jpg"
                    large="/gallery/IMG_8730.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8736.jpg"
                    large="/gallery/IMG_8736.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8742.jpg"
                    large="/gallery/IMG_8742.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8743.jpg"
                    large="/gallery/IMG_8743.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8746.jpg"
                    large="/gallery/IMG_8746.jpg"
                    hideDownload
                    hideZoom
                />

                <div className="horizontal">
                    <ModalImage
                        small="/gallery/IMG_8747.jpg"
                        large="/gallery/IMG_8747.jpg"
                        hideDownload
                        hideZoom
                    />
                </div>

                <div className="horizontal">
                    <ModalImage
                        small="/gallery/IMG_8750.jpg"
                        large="/gallery/IMG_8750.jpg"
                        hideDownload
                        hideZoom
                    />
                </div>

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8707.jpg"
                    large="/gallery/IMG_8707.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8755.jpg"
                    large="/gallery/IMG_8755.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8757.jpg"
                    large="/gallery/IMG_8757.jpg"
                    hideDownload
                    hideZoom
                />

                <div className="horizontal">
                    <ModalImage
                        small="/gallery/IMG_8762.jpg"
                        large="/gallery/IMG_8762.jpg"
                        hideDownload
                        hideZoom
                    />
                </div>

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8767.jpg"
                    large="/gallery/IMG_8767.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8768.jpg"
                    large="/gallery/IMG_8768.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8703.jpg"
                    large="/gallery/IMG_8703.jpg"
                    hideDownload
                    hideZoom
                />

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8769.jpg"
                    large="/gallery/IMG_8769.jpg"
                    hideDownload
                    hideZoom
                />

                <div className="horizontal">
                    <ModalImage
                        small="/gallery/IMG_8770.jpg"
                        large="/gallery/IMG_8770.jpg"
                        hideDownload
                        hideZoom
                    />
                </div>

                <div className="horizontal">
                    <ModalImage
                        small="/gallery/IMG_8773.jpg"
                        large="/gallery/IMG_8773.jpg"
                        hideDownload
                        hideZoom
                    />
                </div>

                <ModalImage
                    class="vertical"
                    small="/gallery/IMG_8775.jpg"
                    large="/gallery/IMG_8775.jpg"
                    hideDownload
                    hideZoom
                />
            </div>
            <div className="paddedBottom"></div>

            <div className="centered">
                <hr/>
                <p className="sub">
                    website designed and developed by carsyn
                </p>
            </div>
        </>
    );
}