import React from "react";
import Toolbar from "../pageDefaults/toolbar";
import "../css/toolbar.css";
import "../css/fonts.css";
import "../css/styles.css";

export default function Home(props) {
    return (

        <>
            <Toolbar/>
            <div className="centered">
                <h1>CARSYN & GAVIN</h1>
            </div>

            <div className="centered">
                <img src="/cover.jpg" alt="Main Image" width="auto "height="600"/>
            </div>

            <div className="centered">
                <h3>Ceremony on June 24, 2024</h3>
                <h3>Reception July 12, 2025</h3>
            </div>

            <div className="centered">
                <h2>Our Story</h2>
                <p>
                    Carsyn and Gavin met in freshman year at Baylor through a mutual friend who was in Carsyn's
                    introductory computer science class and who was Gavin's roommate. The two immediately hit
                    it off, spending time playing card games, watching movies, and going to the park. Fast forward two and
                    a half years of laughter, picnic dates, and trips to Six Flags to when Gavin proposed to Carsyn on the
                    same lake where they had their first date.
                </p>
            </div>

            <div className="centered">
                <hr/>
                <p class="sub">
                    website designed and developed by carsyn
                </p>
            </div>
        </>
    );
}
