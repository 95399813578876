import Toolbar from "../pageDefaults/toolbar";
import React from "react";

export default function RSVP(props) {
    return (
        <>
            <Toolbar/>

            <div className="sideBySide">
                <a href="/ceremonyRSVP"><img src="/CeremonyRSVP.jpg" alt="Ceremony RSVP"/></a>
                <a href="/receptionRSVP"><img src="/ReceptionRSVP.jpg" alt="Reception RSVP" style={{marginRight: 0}}/></a>
            </div>

            <div className="centered">
                <hr/>
                <p className="sub">
                    website designed and developed by carsyn
                </p>
            </div>
        </>
    );
}