import Toolbar from "../pageDefaults/toolbar";
import "../css/fonts.css";
import "../css/styles.css";
import React from "react";

export default function Events(props) {
    return (
        <>
            <Toolbar/>
            <div className="centered">
                <h5>In Hawaii</h5>
                <h4>Ceremony</h4>
                <p>
                    <b>June 24, 2024 at 5:30 PM</b><br/>
                    Waliea Beach Resort<br/>
                    3700 Wailea Alanui Dr.<br/>
                    Wailea, HI 96753<br/>
                    Ceremony to be held at the resort, facing the ocean, with pictures
                    on the beach following the ceremony. Exclusive to those who have received an invitation.
                </p>
            </div>

            <div className="centered">
                <h4>Celebration Dinner</h4>
                <p>
                    <b>June 24, 2024 at 7:30 PM</b><br/>
                    Nick's Fishmarket<br/>
                    4100 Wailea Alanui Dr.<br/>
                    Kihei, HI 96753<br/>
                    Dinner after the ceremony. A 5-minute drive from the ceremony. Exclusive to those who have received an invitation.
                </p>
            </div>

            <div className="centered">
                <h5>In Michigan</h5>
                <h4>Reception</h4>
                <p>
                    <b>July 12, 2025 from 6:00-10:00 PM</b><br/>
                    Residence of the aunt and uncle of the bride <br/>
                    3037 Mary St. SE<br/>
                    East Grand Rapids, MI 49506<br/>
                    Join us for dinner, drinks, and dessert to celebrate the marriage of Carsyn and Gavin!<br/>
                    Please note: the reception, originally Summer 2024, has been pushed back 1 year
                </p>
            </div>
            <div className="paddedBottom"></div>

            <div className="centered">
                <hr/>
                <p className="sub">
                    website designed and developed by carsyn
                </p>
            </div>
        </>
    );
}