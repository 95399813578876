import React from "react";
import Toolbar from "../pageDefaults/toolbar";
import "../css/toolbar.css";
import "../css/fonts.css";
import "../css/styles.css";

export default function NotFound(props) {
    return (

        <>
            <Toolbar/>

            <div className="centered">
                <h5>Page Not Found</h5>
            </div>

            <div className="paddedBottom"></div>

            <div className="centered">
                <hr/>
                <p class="sub">
                    website designed and developed by carsyn
                </p>
            </div>
        </>
    );
}
