import Toolbar from "../pageDefaults/toolbar";
import React from "react";

export default function CeremonyRSVP(props) {
    return (
        <>
            <Toolbar/>
            <div className="centered">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdULCrKBULZBcRbb-p1LG3kWF-GGTC2kevCi1WYGFCYF3dN-Q/viewform?embedded=true" width="100%" height="653" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
            </div>

            <div className="centered">
                <hr/>
                <p className="sub">
                    website designed and developed by carsyn
                </p>
            </div>
        </>
    );
}