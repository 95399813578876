export default function Toolbar() {
    return (
        <>
            <div className="icon">
                <a href="/">
                    <img src="/logo-white.png" alt="Site Icon" width="200" height="100"/>
                </a>
            </div>
            <div className="topnav">
                <a href="/">Home</a>
                <a href="/Gallery">Gallery</a>
                <a href="/Events">Events</a>
                <a href="/RSVP">RSVP</a>
                <a href="/Registry">Registry</a>
                <a href="/FAQ">FAQ</a>
            </div>
        </>
    );
}